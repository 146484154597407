<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 日期 -->
    <el-form ref="form" :model="searchData" label-width="">
      <el-form-item label="时间选择">
        <el-date-picker
          size="small"
          v-model="searchData.timeSlot"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeDate"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <!-- 统计 -->
    <div class="count-box">
      <div class="item animate__animated animate__zoomIn animate__faster">
        <div class="detail">
          <div class="left purple">
            <i class="el-icon-s-order"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.totalOrderNum || 0 }}</span>
            <span class="label">总订单数</span>
          </div>
        </div>
      </div>
      <div class="item animate__animated animate__zoomIn animate__faster">
        <div class="detail">
          <div class="left">
            <i class="el-icon-s-data"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.totalOrderMoney || 0 }}</span>
            <span class="label">订单总金额</span>
          </div>
        </div>
      </div>
      <div class="item animate__animated animate__zoomIn animate__faster">
        <div class="detail">
          <div class="left orange">
            <i class="el-icon-money"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.totalPlatformMoney || 0 }}</span>
            <span class="label">平台总收益</span>
          </div>
        </div>
      </div>
      <div class="item animate__animated animate__zoomIn animate__faster">
        <div class="detail">
          <div class="left green">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.totalUser || 0 }}</span>
            <span class="label">总用户</span>
          </div>
        </div>
      </div>
      <div class="item animate__animated animate__zoomIn animate__faster">
        <div class="detail">
          <div class="left blue">
            <i class="el-icon-coin"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.totalCoin || 0 }}</span>
            <span class="label">总萝卜币</span>
          </div>
        </div>
      </div>
      <div class="item animate__animated animate__zoomIn animate__faster">
        <div class="detail">
          <div class="left pink">
            <i class="el-icon-coin"></i>
          </div>
          <div class="right">
            <span class="value">{{ stat.totalConsumeCoin || 0 }}</span>
            <span class="label">总消费萝卜币</span>
          </div>
        </div>
      </div>
    </div>
    <!-- tabs -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="日订单" name="day"></el-tab-pane>
    </el-tabs>
    <el-table :data="list">
      <el-table-column prop="id" label="用户信息" align="left" min-width="200">
        <template slot-scope="{ row }">
          <div class="user-box" v-if="row.user">
            <div class="row">
              <div class="label">ID：</div>
              <div class="value">
                <el-link type="primary" @click="$router.push({ name: 'CustomerList', query: { id: row.user.id } })">{{ row.user.id }}</el-link>
              </div>
            </div>
            <div class="row">
              <div class="label">昵称：</div>
              <div class="value">{{ row.user.nickname }}</div>
            </div>
            <div class="row">
              <div class="label">联系方式：</div>
              <div class="value">{{ row.user.mobile }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="头像" min-width="100" align="left">
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px; border-radius: 5px; margin-right: 10px"
            v-if="row.user"
            :src="row.user.face"
            :preview-src-list="[row.user.face]"
            fit="cover"
          >
            <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="levelDsc" label="等级" align="left" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.user" type="primary" size="mini">{{ row.user.levelDsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="coin" label="提现信息" min-width="210" align="left">
        <template slot-scope="{ row }">
          <div class="withdraw-box">
            <div class="row">
              <div class="label">提现萝卜币：</div>
              <div class="value">
                {{ row.coin }}
              </div>
            </div>
            <div class="row">
              <div class="label">提现金额：</div>
              <div class="value">{{ row.money }}</div>
            </div>
            <div class="row">
              <div class="label">可得金额：</div>
              <div class="value">{{ row.userMoney }}</div>
            </div>
            <div class="row">
              <div class="label">平台收益金额：</div>
              <div class="value">{{ row.platformMoney }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="coin" label="银行卡信息" min-width="240" align="left">
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row">
              <div class="label">持卡人姓名：</div>
              <div class="value">
                {{ row.holderName }}
              </div>
            </div>
            <div class="row">
              <div class="label">银行名称：</div>
              <div class="value">{{ row.bankName }}</div>
            </div>
            <div class="row">
              <div class="label">卡号：</div>
              <div class="value">{{ row.cardNumber }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="审核状态" min-width="120" align="left">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 20" type="primary" size="small">已通过</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">已拒绝</el-tag>
          <el-tag v-if="row.status == 0" type="warning" size="small">待审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>
      <!-- <el-table-column prop="id" label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin: 0 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getDayList" />
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getDayListAPI, getStaAPI } from './api'
export default {
  name: 'List',

  data() {
    return {
      searchData: {
        page: 1,
        pagesize: 10,
        timeSlot: []
      },
      stat: {},
      list: [],
      total: 0,
      activeName: 'day'
    }
  },

  mounted() {
    this.getStat()
    this.getDayList()
  },

  methods: {
    async getStat() {
      const { page, pagesize, timeSlot } = this.searchData
      let params = { page, pagesize }
      if (timeSlot && timeSlot.length > 0) {
        params.startTime = timeSlot[0]
        params.endTime = timeSlot[1]
      }
      this.stat = await getStaAPI(params)
    },
    async getDayList() {
      const { page, pagesize, timeSlot } = this.searchData
      let params = { page, pagesize }
      if (timeSlot && timeSlot.length > 0) {
        params.startTime = timeSlot[0]
        params.endTime = timeSlot[1]
      }
      const res = await getDayListAPI(params)
      this.list = res.records
      this.total = res.totalRow
    },
    changeDate() {
      this.getStat()
      this.getDayList()
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;
      transition: all 0.2s linear;
      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .user-box {
    .row {
      display: flex;
      .label {
        // min-width: 100px;
        text-align: left;
      }
      .value {
        text-align: left;
        // min-width: 150px;
      }
    }
  }
  .withdraw-box {
    .row {
      display: flex;
      .label {
        // min-width: 100px;
        text-align: right;
      }
      .value {
        text-align: left;
        min-width: 100px;
      }
    }
  }
  .count-box {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));

    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    .item {
      display: flex;
      justify-content: center;
      padding: 20px;
      box-shadow: 0 0px 2px 0 rgba(114, 114, 114, 0.1);
      border-radius: 6px;
      transition: all 0.2s linear;
      // background: #f0f2f5;
      &:hover {
        box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1);
        // background: #f0f2f5;
      }
      .detail {
        display: flex;
        align-items: center;
        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          background-color: #409eff;
          margin-right: 20px;
          color: #fff;
          font-size: 28px;
        }
        .right {
          display: flex;
          flex-direction: column;
          .label {
          }
          .value {
            font-weight: 700;
            font-size: 22px;
          }
        }
        .orange {
          background: #feab28;
        }
        .pink {
          background: #fe86c3;
        }
        .purple {
          background: #b480ec;
        }
        .green {
          background: #6dd32f;
        }
        .blue {
          background: #5882bb;
        }
      }
    }
  }
}
</style>
