import { http } from '@/http/axios.js'
// 统计
export function getStaAPI(params) {
  return http({
    url: '/manage/finance/stat',
    method: 'GET',
    params
  })
}
// 统计
export function getDayListAPI(params) {
  return http({
    url: '/api/user/withdrawPage',
    method: 'GET',
    params
  })
}
